<template>
  <div class="listing">
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- add dialog for listing -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure want to delete car form <b>{{ delListingName }}</b> ?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dodelDealCar()"> Yes </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ headTitle }}
        </v-card-title>

        <v-card-actions>
          <v-container style="padding: 30px">
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-row>
                    <v-autocomplete
                      label="Deals*"
                      required
                      :rules="rules.deals"
                      v-model="dealsJSON"
                      :items="autoSearchDealsLists"
                      :search-input.sync="dealsSearch"
                      item-value="name"
                      item-text="name"
                      :loading="isLoading"
                      prepend-icon="mdi-database-search"
                      return-object
                    >
                    </v-autocomplete>
                  </v-row>
                  <v-row>
                    <v-select
                      required
                      :rules="rules.dealerType"
                      v-model="addListing.dealerType"
                      :items="['AD', 'PI', 'Used']"
                      item-value="value"
                      item-text="text"
                      label="Dealer Type*"
                      @change="changeDealerType(addListing.dealerType)"
                    >
                    </v-select>
                  </v-row>
                  <v-row>
                    <v-autocomplete
                      label="Dealer*"
                      required
                      :rules="rules.dealer"
                      v-model="dealerJSON"
                      :items="dealerLists"
                      :search-input.sync="searchDealer"
                      item-value="name"
                      item-text="name"
                      :loading="isLoadingDealer"
                      prepend-icon="mdi-account"
                      return-object
                      @change="changeDealerCar(dealerJSON)"
                    >
                    </v-autocomplete>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        label="Car*"
                        v-model="carJSON"
                        :items="carLists"
                        :search-input.sync="searchCar"
                        item-value="id"
                        item-text="name"
                        :loading="isLoadingCar"
                        prepend-icon="mdi-database-search"
                        return-object
                        @change="selectedCars(carJSON)"
                      >
                      </v-autocomplete>
                      <p
                        v-if="
                          selectedCarLists.length === 0 &&
                            selectedCarModelFrontLists.length === 0 &&
                            isDealerError
                        "
                        class="v-messages__message"
                        style="margin-top: -16px; margin-left: 33px"
                      >
                        Car required
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedCarLists.length > 0">
                    <v-list class="dealer-listing" flat>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, i) in selectedCarLists"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.productTitle"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-icon @click="deleteCarListsSelected(i)"
                              >mdi-close</v-icon
                            >
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-row>
                  <v-row v-if="selectedCarModelFrontLists.length > 0">
                    <v-list class="dealer-listing" flat>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, i) in selectedCarModelFrontLists"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.modelName"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-icon @click="deleteCarModelFrontListSelected(i)"
                              >mdi-close</v-icon
                            >
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doAddListing()"
                  >Submit</v-btn
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- filter search -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchLisiting()"
          v-model="listingSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchLisiting()"
          @keyup.enter.native="searchLisiting()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-btn fab color="red accent-2" @click="openAddListing()">
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <!-- listing data listing table -->
    <v-data-table
      :headers="headers"
      :items="dealsLists"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>
            <span v-if="row.item.carList.length !== 0">{{
              row.item.carList[0].productTitle
            }}</span>
            <span v-if="row.item.carModelFrontList.length !== 0">
              {{ row.item.carModelFrontList[0].modelName }}
            </span>
          </td>
          <td>{{ row.item.name }}</td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
import postAxios from "../../../services/axios-post";
// import postAxios from "../../../services/axios-post";
// import deleteAxios from "../../../services/axios-delete";
Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      isLoading: false,
      isLoadingDealer: false,
      isLoadingCar: false,
      search: null,
      headTitle: null,
      listingSearch: null,
      addDialog: false,
      snackbar: false,
      snackMessage: null,
      selectedCarLists: [],
      selectedCarModelFrontLists: [],
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Car", value: "car", sortable: false },
        { text: "Deals", value: "name" },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      listingList: [],
      addListing: {
        deals: null,
        dealerType: null,
        dealer: null,
        car: null,
      },
      editListing: {
        deals: null,
        dealerType: null,
        dealer: null,
        car: null,
      },
      dealsJSON: null,
      dealsLists: [],
      autoSearchDealsLists: [],
      rules: {
        deals: [v => !!v || "Deal required"],
        dealerType: [v => !!v || "Dealer type required"],
        dealer: [v => !!v || "Dealer required"],
      },
      delListing: null,
      delListingName: null,
      deleteDialog: false,
      dealerLists: [],
      carLists: [],
      carJSON: null,
      dealerJSON: null,
      searchDealer: null,
      searchCar: null,
      dealsSearch: null,
      isDealerError: false,
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.getDeals();
    this.dealerChange();
    this.atuoSearchDeal();
  },
  watch: {
    dealsSearch(val) {
      if (this.isLoading) return;
      this.isLoading = true;
      this.atuoSearchDeal(val);
    },
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.getDeals();
    },
    openAddListing() {
      this.addDialog = true;
      this.headTitle = "Add Listing";
    },
    deleteCarListsSelected(index) {
      this.selectedCarLists.splice(index, 1);
    },
    deleteCarModelFrontListSelected(index) {
      this.selectedCarModelFrontLists.splice(index, 1);
    },
    changeDealerType(dealerType) {
      this.carLists = [];
      const isAuthorized = dealerType === "AD" ? "1" : "0";
      const params = {
        isAuthorized: isAuthorized,
        size: 1000,
        page: 0,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealerCampaign`,
        params
      ).then(res => {
        if (res.data) {
          this.dealerLists = res.data.data.content;
        }
      });
    },
    changeDealerCar(dealer) {
      this.carLists = [];
      if (this.addListing.dealerType === "AD") {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/carInfo/ad-newCarFilter?size=1000&page=0`,
          {
            dealerId: dealer.id,
          }
        ).then(res => {
          if (res.data) {
            const cars = res.data.data.content;
            for (let car of cars) {
              if (dealer.id === car.dealerId) {
                this.carLists.push({
                  id: car.modelFrontId,
                  name: car.modelName,
                });
              }
            }
          }
        });
      } else if (this.addListing.dealerType === "Used") {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/carInfo/usedCarFilter?size=1000&page=0`,
          {
            dealerId: dealer.id,
          }
        ).then(res => {
          if (res.data) {
            const cars = res.data.data.content;
            for (let car of cars) {
              if (dealer.id === car.dealerId) {
                this.carLists.push({ id: car.id, name: car.productTitle });
              }
            }
          }
        });
      } else if (this.addListing.dealerType === "PI") {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/carInfo/newCarFilter?size=1000&page=0`,
          {
            dealerId: dealer.id,
          }
        ).then(res => {
          if (res.data) {
            const cars = res.data.data.content;
            for (let car of cars) {
              if (dealer.id === car.dealerId) {
                this.carLists.push({ id: car.id, name: car.productTitle });
              }
            }
          }
        });
      }
    },
    searchLisiting() {
      this.getDeals();
    },
    atuoSearchDeal(val) {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealSystem/dealData`,
        { searchContent: val },
        this.token
      )
        .then(res => {
          if (res.data) {
            this.autoSearchDealsLists = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    getDeals() {
      const params = {
        page: this.pagination.page - 1,
        size: this.pagination.limit,
        searchContent: this.listingSearch,
        sort: this.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealSystem/dealData`,
        params,
        this.token
      )
        .then(res => {
          if (res.data) {
            this.dealsLists = res.data.data.content;
            this.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.getDeals();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.addDialog = true;
      this.headTitle = "Edit Listing";
    },
    setEditItemInfo(item) {
      this.dealsJSON = item;
      item.carModelFrontList.length > 0
        ? (this.selectedCarModelFrontLists = item.carModelFrontList)
        : (this.selectedCarModelFrontLists = []);
      item.carList.length > 0
        ? (this.selectedCarLists = item.carList)
        : (this.selectedCarLists = []);
    },
    resetAddItemInfo() {
      this.dealsJSON = null;
      this.addListing.dealerType = null;
      this.dealerJSON = null;
      this.carJSON = null;
      this.selectedCarLists = [];
      this.selectedCarModelFrontLists = [];
    },
    selectedCars(car) {
      if (this.addListing.dealerType === "AD") {
        if (!this.selectedCarModelFrontLists.find(item => item.id === car.id)) {
          const carObj = { id: car.id, modelName: car.name };
          this.selectedCarModelFrontLists.push(carObj);
          this.carJSON = { id: null, name: null };
        }
      } else {
        if (!this.selectedCarLists.find(item => item.id === car.id)) {
          const carObj = { id: car.id, productTitle: car.name };
          this.selectedCarLists.push(carObj);
          this.carJSON = { id: null, name: null };
        }
      }
    },
    async doAddListing() {
      try {
        this.isDealerError =
          this.selectedCarLists.length === 0 &&
          this.selectedCarModelFrontLists.length === 0
            ? true
            : false;
        if (this.$refs.form.validate() && !this.isDealerError) {
          this.dealsJSON.carList = [];
          this.selectedCarLists.map(car => {
            this.dealsJSON.carList.push(car?.id);
          });
          this.dealsJSON.carModelFrontList = [];
          this.selectedCarModelFrontLists.map(car => {
            this.dealsJSON.carModelFrontList.push(car?.id);
          });
          this.dealsJSON.campaignId = this.dealsJSON.campaign.id;
          this.dealsJSON.img = this.dealsJSON.img
            ? this.dealsJSON.img
                .split("https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/")
                .slice(-1)[0]
            : null;
          putAxios(
            `${process.env.VUE_APP_SERVER_URL}/dealSystem/updDeal`,
            this.dealsJSON
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                this.addDialog = false;
                this.getDeals();
                this.resetAddItemInfo();
                this.snackbar = true;
                this.snackMessage = "Car added successfully";
              }
            })
            .catch(e => {
              console.log(e);
              this.snackbar = true;
              this.snackMessage = "Error!";
            });
        }
      } catch (e) {
        console.error(e);
      }
    },
    dealerChange() {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealerCampaign`
      ).then(res => {
        if (res.httpStatus === "OK") {
          this.addDialog = false;
        }
      });
    },
    delButtonClick(deal) {
      this.delListing = deal;
      this.delListingName = deal.name;
      this.deleteDialog = true;
      this.dealsJSON = deal;
    },
    dodelDealCar() {
      this.dealsJSON.carList = [];
      this.dealsJSON.carModelFrontList = [];
      this.dealsJSON.campaignId = this.dealsJSON.campaign.id;
      this.dealsJSON.img = this.dealsJSON.img
        ? this.dealsJSON.img
            .split("https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/")
            .slice(-1)[0]
        : null;
      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/dealSystem/updDeal`,
          this.dealsJSON
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            this.deleteDialog = false;
            this.getDeals();
            this.snackbar = true;
            this.snackMessage = "Car removed successfully";
          }
        });
      } catch (e) {
        console.error(e);
        this.snackbar = true;
        this.snackMessage = "Error!";
      }
    },
  },
};
</script>
<style>
.dealer-listing {
  width: 100%;
  background-color: rgb(241, 241, 241) !important;
  border-radius: 5px !important;
}
.v-application .error--text {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-messages__message {
  color: red !important;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
}
</style>
